import { FCLTransactionResult } from "flowty-common"
import * as t from "@onflow/types"
import * as fcl from "@onflow/fcl"
import { Config } from "../../types"
import { Err, sendMutation } from "../../utils/Utils"
import { createOpenEditionTransactionFn } from "../transactions/createOpenEditionTx"
import { CreateOpenEditionProps } from "./CreateOpenEditionTypes"

export class CreateOpenEdition {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	createOpenEdition = async ({
		collectionDetails,
		nftDetails,
		dropDetails,
		phaseDetails,
		txAvailableCallback,
	}: CreateOpenEditionProps): Promise<FCLTransactionResult> => {
		const metadataDict: { [key: string]: string } = {
			cid: nftDetails.nftThumbnail,
			description: nftDetails.nftDescription,
			externalURL: nftDetails.nftExternalURL,
			name: nftDetails.nftName,
		}
		const metadataArgs = Object.entries(metadataDict).map(entry => {
			return { key: entry[0], value: entry[1] }
		})

		const collectionInfoDict: { [key: string]: string } = {
			bannerImageCid: collectionDetails.collectionPageBannerImage,
			bannerImageMediaType: collectionDetails.collectionBannerImageMediaType,
			description: collectionDetails.collectionDescription,
			externalURL: collectionDetails.collectionExternalURL,
			name: collectionDetails.collectionName,
			squareImageCid: collectionDetails.collectionSquareImage,
			squareImageMediaType: collectionDetails.collectionSquareImageMediaType,
		}

		if (collectionDetails.collectionTwitterHandle) {
			collectionInfoDict.twitter = collectionDetails.collectionTwitterHandle
		}

		if (collectionDetails.collectionInstagramHandle) {
			collectionInfoDict.instagram = collectionDetails.collectionInstagramHandle
		}

		if (collectionDetails.collectionDiscordHandle) {
			collectionInfoDict.discord = collectionDetails.collectionDiscordHandle
		}

		const collectionInfoArgs = Object.entries(collectionInfoDict).map(entry => {
			return { key: entry[0], value: entry[1] }
		})

		const dropDetailDict: { [key: string]: string } = {
			description: dropDetails.dropDescription,
			name: dropDetails.dropName,
			nftType: dropDetails.dropNftType || "",
			thumbnailCid: dropDetails.dropThumbnail,
		}

		const dropDetailArgs = Object.entries(dropDetailDict).map(entry => {
			return { key: entry[0], value: entry[1] }
		})

		let royalty = collectionDetails.collectionRoyalty ?? 0
		// handle floating point math issues
		// 2.5% -> 250 -> 0.025
		// This is to prevent 2.5% -> 0.024999...999 or 2.5% -> 0.025000...0001
		royalty = (royalty * 100) / 10_000

		// transaction(name: String, managerInitialTokenBalance: UFix64, start: UInt64, end: UInt64, price: UFix64, paymentTokenType: String, phaseArgs: {String: String}, metadataArgs: {String: String}, collectionInfoArgs: {String: String}, dropDetailArgs: {String: String}) {
		const txArgs = [
			fcl.arg(collectionDetails.contractName, t.String),
			fcl.arg("0.01", t.UFix64),
			fcl.arg(phaseDetails[0].phaseStartDate, t.Optional(t.UInt64)),
			fcl.arg(phaseDetails[0].phaseEndDate, t.Optional(t.UInt64)),
			fcl.arg(Number(phaseDetails[0].phasePrice).toFixed(5), t.UFix64),
			fcl.arg(this.config.getIdentifier("FlowToken", "Vault"), t.String),
			fcl.arg([], t.Dictionary({ key: t.String, value: t.String })),
			fcl.arg(metadataArgs, t.Dictionary({ key: t.String, value: t.String })),
			fcl.arg(
				collectionInfoArgs,
				t.Dictionary({ key: t.String, value: t.String })
			),
			fcl.arg(dropDetailArgs, t.Dictionary({ key: t.String, value: t.String })),
			fcl.arg(royalty.toFixed(5) || null, t.Optional(t.UFix64)),
		]
		console.log("txArgs", txArgs)

		const createOpenEditionTx = createOpenEditionTransactionFn(this.config)
		try {
			const res = await sendMutation(
				createOpenEditionTx,
				txArgs,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createOpenEdition Error", e)
			throw e
		}
	}
}
