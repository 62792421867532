import { ReactComponent as CreatorHubPromo } from "./../../../assets/creator-hub-promo.svg"

export interface Promotion {
	image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	promotionId: string
	title: string
	message: React.ReactNode
	learnMoreUrl: string
}

export const promotion: Promotion[] = [
	{
		image: CreatorHubPromo,
		learnMoreUrl: "flowty.io", //TODO update this with link to flowty doc (not yet created)
		message: (
			<div>
				<div>
					Get ready to create in style! We’re thrilled to announce an exciting
					giveaway featuring our brand-new collections. Here’s your chance to
					snag the latest and greatest with:
				</div>
				<br />
				<ul className='list-disc pl-5'>
					<li>The most minted NFTs in our collection</li>
					<li>A special prize for the most unique wallets</li>
				</ul>
				<br />
				Don’t miss out on this exclusive opportunity to be part of the hype!
				Stay tuned for more details...
			</div>
		),

		promotionId: "creators-hub-live",
		title: "Creator’s Hub Live On Flowty",
	},
]
