import { Dialog, Transition } from "@headlessui/react"
import { ComponentType, Fragment, useEffect, useState } from "react"
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close-x.svg"
import { FlowtyButton } from "ds-flowty"
import { inject, observer } from "mobx-react"
import AuthStore from "../../../stores/AuthStore"
import { updateAccountData } from "../../../services/firestore/AccountService"
import { actions as Mixpanel } from "../../../util/Mixpanel"

interface PromotionalMessageProps {
	promotionId: string
	title: string
	message: React.ReactNode
	learnMoreUrl: string
	image: ComponentType<React.SVGProps<SVGSVGElement>>
	authStore: AuthStore | undefined
}

const PromotionalMessage: React.FC<PromotionalMessageProps> = ({
	promotionId,
	title,
	message,
	learnMoreUrl,
	image: Image,
	authStore,
}) => {
	const [isVisible, setIsVisible] = useState(false)

	const user = authStore?.loggedUser
	const loggedUserAcknowledged = user?.hasAcknowledgedPromotions?.[promotionId]
	const localStorageKey = `featurePopupAcknowledged_${promotionId}`

	useEffect(() => {
		const localAcknowledged = localStorage.getItem(localStorageKey) === "true"

		if (
			(user?.addr && !loggedUserAcknowledged) ||
			(!user?.addr && localAcknowledged === false)
		) {
			setIsVisible(true)
		}
	}, [user, promotionId, localStorageKey])

	const handleClose = async () => {
		setIsVisible(false)
		if (user?.addr) {
			await updateAccountData(user?.addr, {
				hasAcknowledgedPromotions: {
					[promotionId]: true,
				},
			})
			Mixpanel.track("User acknowledged promotion", { promotionId })
			authStore?.setPromotionAcknowledged(promotionId, true)
		}
		localStorage.setItem(localStorageKey, "true")
	}

	if (!isVisible) {
		return null
	}

	return (
		<Transition appear show={isVisible} as={Fragment}>
			<Dialog as='div' className='relative z-40' static onClose={() => null}>
				<Transition.Child
					enter='transition duration-000 ease-out'
					enterFrom='transform scale-95 opacity-0'
					enterTo='transform scale-100 opacity-100'
					leave='transition duration-75 ease-out'
					leaveFrom='transform scale-100 opacity-100'
					leaveTo='transform scale-95 opacity-0'
					as={Fragment}
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-1000'
						enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-0'
						leaveFrom='opacity-0 scale-100'
						leaveTo='opacity-0 scale-95'
					>
						<Dialog.Panel className='relative z-40 shadow-xl  '>
							<div className='flex flex-col w-[300px] h-auto md:w-[764px] md:h-[805px] bg-darkBg rounded-[10px] border border-[#343A40]'>
								<div className='flex flex-row justify-between p-4 md:pt-[39px] md:px-[39px] md:pb-[36px]'>
									<h2 className='text-[var(--White,#FFF)] font-montserrat text-12px md:text-[24px] font-black leading-none [text-shadow:0px_4px_4px_rgba(0,0,0,0.25)]'>
										{title}
									</h2>
									<CloseIcon
										className='cursor-pointer w-32px h-32px'
										onClick={handleClose}
									/>
								</div>
								<div className='flex flex-col py-4 px-4 md:py-[36px] md:px-[38px] gap-[10px] md:gap-[40px] border border-r-0 border-l-0 border-t-solid [border-top-color:var(--Neutral-200,#343A40)] border-b-solid [border-bottom-color:var(--Neutral-200,#343A40)]'>
									<div className='flex justify-center items-center'>
										<Image className='w-auto h-auto' aria-label={promotionId} />
									</div>

									<div className='text-[var(--Neutral-700,#DEE2E6)] font-montserrat text-[10px] md:text-[14px] font-normal md:font-medium leading-[16px] tracking-[0.28px]'>
										{message}
									</div>
								</div>
								<div className='flex h-full justify-center items-center md:px-[38px]'>
									<FlowtyButton
										text={"Learn More"}
										variant={"secondary"}
										bgColor='white'
										onClick={() => {
											window.open(learnMoreUrl, "_blank")
											Mixpanel.track("Clicked on Creator Hub Learn More Link")
										}}
									/>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}

export default inject("authStore")(observer(PromotionalMessage))
