import { FlowtyButton } from "ds-flowty"
import React from "react"

export const CreatorHubNoCollections: React.FunctionComponent = () => {
	return (
		<div className='flex flex-col gap-[18px] items-center'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='118'
				height='106'
				viewBox='0 0 118 106'
				fill='none'
			>
				<g opacity='0.7'>
					<path
						d='M76.5 22.5L81.5 0H44C30.8 2.4 23.5 15.3333 21.5 21.5L0 105H26L35.5 67H53.5L58.5 43.5H41.5C46 27.5 47.5 22.5 50.5 22.5H76.5Z'
						fill='white'
					/>
					<path
						d='M60.5 95C60.5 101.075 55.5751 106 49.5 106C43.4249 106 38.5 101.075 38.5 95C38.5 88.9249 43.4249 84 49.5 84C55.5751 84 60.5 88.9249 60.5 95Z'
						fill='#B3BBBE'
					/>
					<path
						d='M89 95C89 101.075 84.0751 106 78 106C71.9249 106 67 101.075 67 95C67 88.9249 71.9249 84 78 84C84.0751 84 89 88.9249 89 95Z'
						fill='#B3BBBE'
					/>
					<path
						d='M117.5 95C117.5 101.075 112.575 106 106.5 106C100.425 106 95.5 101.075 95.5 95C95.5 88.9249 100.425 84 106.5 84C112.575 84 117.5 88.9249 117.5 95Z'
						fill='#B3BBBE'
					/>
				</g>
			</svg>
			<div className='text-[#B3B7BC] text-[14px] font-bold leading-normal'>
				NO COLLECTIONS FOUND
			</div>
			<div className=' w-[265px]'>
				<FlowtyButton
					onClick={() => {
						window.location.replace("/create")
					}}
					text='Create New Collection'
					variant={"secondary"}
					btnSize='large'
					bgColor='white'
				/>
			</div>
		</div>
	)
}
