import { DapperSignInBar, FlowtyNavbar, useBreakpoint } from "ds-flowty"
import {
	NavItemName,
	navItems,
} from "ds-flowty/src/FlowtyNavbar/types/NavbarTypes"
import { inject, observer } from "mobx-react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { GlobalAlert } from "../components/LandingPage/GlobalAlert/index"
import TermsHaveBeenUpdatedModal from "../components/Modals/UpdatedTerms/UpdatedTerms"
import WelcomeMessageModal from "../components/Modals/WelcomeMessage/WelcomeMessage"
import { WalletConnectedButton } from "../components/Navbar/components/WalletConnectedButton"
import { useNavbarViewContext } from "../contexts/NavbarViewContext"
import { useNotifications } from "../hooks/useNotifications"
import { AuthStoreProp } from "../stores/AuthStore"
import { RootStoreProp } from "../stores/RootStore"
import { connectWallet } from "../util/ConnectWallet"
import { IS_MAINNET, IS_CREATOR_HUB_ENABLED } from "../util/settings"
import { connectDapperWallet } from "../util/ConnectDapperWallet"
import { useHandleDapperSignInBar } from "../hooks/useHandleDapperSignInBar"
import { useSporkContext } from "../contexts/SporkContext"
import { PageBanner } from "../components/PageBanner/PageBanner"
import PromotionalMessage from "../components/Modals/PromotionalMessage/PromotionalMessage"
import { promotion } from "../components/Modals/PromotionalMessage/promotions"

interface LayoutProps extends AuthStoreProp, RootStoreProp {
	children: React.ReactElement | React.ReactElement[]
	landingPage?: boolean
}

const Layout: React.FC<LayoutProps> = ({
	children,
	authStore,
	landingPage,
}) => {
	const breakpoint = useBreakpoint()

	const { pathname } = useLocation()

	authStore?.loadActiveGlobalAlerts()

	const [authLoading, setAuthLoading] = useState<boolean>(false)

	useEffect(() => {
		if (authStore?.loggedUser?.loggedIn) {
			setAuthLoading(false)
		}
	}, [authStore?.loggedUser?.loggedIn])

	const { visibleInNav, setNavbarOffsetHeight, navbarOffsetHeight } =
		useNavbarViewContext()

	const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false)

	const [showLatestTermsModal, setShowLatestTermsModal] =
		useState<boolean>(false)

	const loggedUserHasNoEmail =
		authStore && authStore.loggedUser && authStore.loggedUser.email === ""

	const loggedUserHasNoMarketingEmail =
		authStore &&
		authStore.loggedUser &&
		(!authStore.loggedUser.marketingEmail ||
			authStore.loggedUser.marketingEmail === "")

	useEffect(() => {
		if (
			((loggedUserHasNoEmail &&
				!authStore.loggedUser?.welcomePopupAcknowledged) ||
				(IS_MAINNET &&
					loggedUserHasNoMarketingEmail &&
					!authStore.loggedUser?.welcomePopupAcknowledged)) &&
			pathname !== "/tos" &&
			pathname !== "/privacy"
		) {
			setShowWelcomeMessage(true)
		} else {
			if (
				authStore?.loggedUser &&
				!authStore.loggedUser?.hasAcceptedTermsV2 &&
				pathname !== "/tos" &&
				pathname !== "/privacy"
			) {
				setShowLatestTermsModal(true)
			} else {
				setShowLatestTermsModal(false)
			}
		}
	}, [
		authStore?.loggedUser,
		loggedUserHasNoMarketingEmail,
		loggedUserHasNoEmail,
	])

	const selectedItem = useMemo<NavItemName | undefined>(() => {
		if (pathname.includes("/collection")) {
			return
		}

		const activeItem = Object.entries(navItems).find(([, value]) => {
			return pathname.includes(value)
		})

		if (activeItem) {
			return activeItem[0] as NavItemName
		}
	}, [pathname])

	const getPaddingBottom = useCallback(() => {
		if (["xs", "mobile", "tablet"].includes(breakpoint)) {
			return navbarOffsetHeight
		}
		if (!landingPage) {
			return `${navbarOffsetHeight}px`
		} else {
			return `${navbarOffsetHeight}px`
		}
	}, [
		breakpoint,
		landingPage,
		navbarOffsetHeight,
		pathname,
		selectedItem,
		visibleInNav,
	])

	const {
		notifications,
		queueNotification,
		fetchMoreNotifications,
		hasMore,
		loading,
	} = useNotifications({
		autoResolve: false,
		loggedUserAddress: authStore?.loggedUser?.addr || "",
	})

	const { isShowing } = useSporkContext()

	const { showDapperSignInBar, setShowDapperSignInBar } =
		useHandleDapperSignInBar()

	return (
		<div className={`App h-full ${landingPage && "bg-[#04070b]"}`}>
			<GlobalAlert />
			{showWelcomeMessage && <WelcomeMessageModal authStore={authStore} />}
			{showLatestTermsModal && (
				<TermsHaveBeenUpdatedModal authStore={authStore} />
			)}
			<PromotionalMessage
				promotionId={promotion[0]?.promotionId}
				title={promotion[0]?.title}
				message={promotion[0]?.message}
				learnMoreUrl={promotion[0]?.learnMoreUrl}
				image={promotion[0]?.image}
				authStore={authStore}
			/>
			<div
				style={{
					paddingBottom: isShowing ? 0 : getPaddingBottom(),
				}}
			>
				<FlowtyNavbar
					isMainnet={Boolean(IS_MAINNET)}
					hasMore={hasMore}
					fetchMoreNotifications={fetchMoreNotifications}
					notifications={notifications}
					queueNotification={queueNotification}
					loadingNotifications={loading}
					navItems={Object.entries(navItems).map(([name, href]) => {
						if (name === "Create" && !IS_CREATOR_HUB_ENABLED) return <></>

						return (
							<li
								key={name}
								className={`py-[12px] ${
									selectedItem === name ? "pl-[12px] pr-[24px]" : "px-[24px]"
								}`}
							>
								<Link
									to={
										name === "Profile"
											? !!authStore?.loggedUser?.loggedIn
												? href
												: "/signIn"
											: href
									}
									className={`flex items-center hover-nav-item transform text-size-[.875rem] rounded-md tracking-wider whitespace-nowrap font-black uppercase text-white duration-300 ease-in-out gap-[8px]`}
									aria-current={selectedItem === name ? "page" : undefined}
								>
									{selectedItem === name && (
										<div className='rounded bg-[#04E5A3] h-[14px] w-1'></div>
									)}
									{name === "Profile" ? "My Profile" : name}
								</Link>
							</li>
						)
					})}
					registerHeight={(height: number) => setNavbarOffsetHeight(height)}
					isLandingPage={!!landingPage}
					isCreatePage={pathname === "/create"}
					isLogged={!!authStore?.loggedUser?.loggedIn}
					loggedPopoverComponent={
						authStore?.loggedUser?.loggedIn ? <WalletConnectedButton /> : null
					}
					mixpanelFn={() => {}}
					selectedItem={selectedItem}
					disableActions={false}
					logUser={() => connectWallet(setAuthLoading)}
					authLoading={authLoading}
					searchControlsComponent={null}
				/>
				<div style={{ marginTop: isShowing ? navbarOffsetHeight : 0 }}>
					<PageBanner />
				</div>
			</div>
			{!authStore?.loggedUser?.loggedIn &&
				showDapperSignInBar &&
				pathname !== "/create" && (
					<DapperSignInBar
						onDismiss={() => {
							setShowDapperSignInBar(false)
						}}
						connectDapperWallet={() => connectDapperWallet()}
					/>
				)}
			{children}
		</div>
	)
}

export default inject("rootStore", "authStore")(observer(Layout))
