import { FlowtyButton } from "ds-flowty"
import { AuthStoreProp } from "../../stores/AuthStore"
import { CreatorHubCard } from "./components/CreatorHubCard"
import { CreatorHubLoadingCard } from "./components/CreatorHubLoadingCard"
import { useProfileCreatorHub } from "./hooks/useProfileCreatorHub"
import Layout from "../Layout"
import { inject, observer } from "mobx-react"
import { CreatorHubNoCollections } from "./components/CreatorHubNoCollections"
import { useMemo } from "react"

const ProfileCreatorHubComponent: React.FC<AuthStoreProp> = ({ authStore }) => {
	const { isLoading, sortedCollections, getDropStatus } = useProfileCreatorHub({
		authStore: authStore,
	})

	const renderCollections = useMemo(() => {
		if (isLoading) {
			return (
				<div className='flex gap-y-[12px] gap-x-[16px] 3xl:gap-x-[18px] flex-wrap'>
					{Array.from({ length: 10 }).map((_, idx) => (
						<div
							key={idx}
							className='flex w-full sm:w-[271px] py-[16px] sm:py-[12px] px-[12px] sm:px-[14px] flex-col sm:items-start gap-[12px] rounded-[6.339px] border-[0.792px] border-neutral-300 bg-gradient-to-br from-[rgba(255,255,255,0.12)] to-[rgba(255,255,255,0.06)] backdrop-blur-[16.640px]'
						>
							<CreatorHubLoadingCard />
						</div>
					))}
				</div>
			)
		}

		if (!sortedCollections.length) {
			return (
				<div className='flex justify-center items-center mt-16 lg:mt-24 2xl:mt-36'>
					<CreatorHubNoCollections />
				</div>
			)
		}

		if (sortedCollections.length > 0) {
			return (
				<div className='mt-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-auto 2xl:grid-cols-5 3xl:grid-cols-7 gap-[12px] max-sm:justify-center'>
					{sortedCollections.map((contract, index) => {
						const collectionName = contract?.collectionDisplay?.name
							.split(" ")
							.join("")

						return (
							<CreatorHubCard
								key={`${index} + ${contract?.collectionDisplay?.name}`}
								contract={contract}
								collectionName={collectionName}
								getDropStatus={getDropStatus}
							/>
						)
					})}
				</div>
			)
		}
	}, [isLoading, sortedCollections])

	return (
		<div className='min-h-screen bg-cover bg-no-repeat bg-center bg-fixed mb-8'>
			<Layout>
				<div className='container'>
					<div className='flex flex-col gap-[24px] mt-[12px]'>
						<div className='flex flex-row justify-between'>
							<div className='text-white font-montserrat text-[24px] font-black leading-[32px] tracking-[0.8px] '>
								CREATOR HUB
							</div>
							{sortedCollections.length > 0 && (
								<>
									{" "}
									<div className=' w-[265px] hidden sm:inline-block '>
										<FlowtyButton
											onClick={() => {
												window.location.replace("/create")
											}}
											text='Create New Collection'
											variant={"secondary"}
											btnSize='large'
											bgColor='white'
										/>
									</div>
									<div className='inline-block sm:hidden '>
										<FlowtyButton
											onClick={() => {
												window.location.replace("/create")
											}}
											text='Create'
											variant={"secondary"}
											btnSize='large'
											bgColor='white'
										/>
									</div>
								</>
							)}
						</div>
						{renderCollections}
					</div>
				</div>
			</Layout>
		</div>
	)
}

export const ProfileCreatorHub = inject("authStore")(
	observer(ProfileCreatorHubComponent)
)
