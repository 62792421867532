/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatAddress, TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getStorefrontListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean
): string =>
	config.crescendo
		? storefrontListingTxnCrescendo(config, token, nftData, isNftCatalog)
		: storefrontListingTxn(config, token, nftData, isNftCatalog)

export const getBulkStorefrontListingTxn = (
	config: Config,
	token: TokenMetadata
): string => {
	return config.crescendo
		? bulkListingTxnCrescendo(config, token)
		: bulkListingTxn(config, token)
}

export const getDelistStorefrontListingTxn = (
	config: Config,
	listingType: string
): string => {
	const listingTypeAddress = formatAddress(listingType.split(".")[1])
	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2) {
		return config.crescendo ? delistItemCrescendo(config) : delistItem(config)
	}

	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2_Shared) {
		return config.crescendo
			? delistItemSharedStorefrontItemCrescendo(config)
			: delistSharedStorefrontItem(config)
	}

	throw new Error("unknown listing type")
}

export const getBulkDelistStorefrontListingTxn = (config: Config): string => {
	return config.crescendo
		? bulkDelistTxnCrescendo(config)
		: bulkDelistTxn(config)
}

const bulkListingTxn = (config: Config, token: TokenMetadata): string => {
	if (token.symbol === "DUC") {
		return bulkListingTxnDapperBalance(config)
	}

	return bulkListingTxnNonCustodial(config)
}

const bulkListingTxnCrescendo = (
	config: Config,
	token: TokenMetadata
): string => {
	if (token.symbol === "DUC") {
		return bulkListingTxnDapperBalanceCrescendo(config)
	}

	return bulkListingTxnNonCustodialCrescendo(config)
}

const bulkListingTxnDapperBalance = (config: Config): string => ``

const bulkListingTxnNonCustodial = (config: Config): string => ``

const bulkListingTxnDapperBalanceCrescendo = (
	config: Config
): string => `// Flowty - List multiple items for sale at the same time
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import TransactionTypes from ${config.contractAddresses.TransactionTypes}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}
import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(saleRequests: [TransactionTypes.StorefrontListingRequest]) {
    prepare(seller: auth(Storage, Capabilities) &Account) {
        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            let storefront <- NFTStorefrontV2.createStorefront()
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)

            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
                seller.capabilities.storage.issue<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontStoragePath), 
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let paymentReceiver = seller.capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
        assert(paymentReceiver.check() != nil, message: "Missing or mis-typed DapperUtilityCoin receiver")

        let nftCache: {String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = {}
        let nftRef: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = &nftCache

        let typeCache: {String: Type} = {}
        let typeRef: auth(Mutate) &{String: Type} = &typeCache

        let storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)!
        for request in saleRequests {
          createListing(seller, storefront, request, paymentReceiver, nftRef, typeRef)
        }
    }
}

access(all) fun createListing(
  _ seller: auth(Storage, Capabilities) &Account,
  _ storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ paymentReceiver: Capability<&{FungibleToken.Receiver}>,
  _ nftCache: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ typeCache: auth(Mutate) &{String: Type}
) {
  if typeCache[r.nftTypeIdentifier] == nil {
    typeCache[r.nftTypeIdentifier] = CompositeType(r.nftTypeIdentifier) ?? panic("invalid nft type identifier")
  }

  let collectionCap = getCollectionCap(seller, r, nftCache, typeCache[r.nftTypeIdentifier]!)

  // check for existing listings of the NFT
  var existingListingIDs = storefront.getExistingListingIDs(
      nftType: typeCache[r.nftTypeIdentifier]!,
      nftID: r.nftID
  )
  // remove existing listings
  for listingID in existingListingIDs {
      storefront.removeListing(listingResourceID: listingID)
  }

  // Create listing
  storefront.createListing(
    nftProviderCapability: collectionCap,
    paymentReceiver: paymentReceiver,
    nftType: typeCache[r.nftTypeIdentifier]!,
    nftID: r.nftID,
    salePaymentVaultType: Type<@DapperUtilityCoin.Vault>(),
    price: r.price,
    customID: r.customID,
    expiry: UInt64(getCurrentBlock().timestamp) + r.expiry,
    buyer: r.buyerAddress
  )
}

access(all) fun getCollectionCap(
  _ seller: auth(Storage, Capabilities) &Account,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ nftCache: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ nftType: Type
): Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}> {
  let key = r.nftProviderAddress.toString().concat(r.nftTypeIdentifier)
  if nftCache[key] != nil {
    return nftCache[key]!
  }

  if r.nftProviderAddress == seller.address {
    let storagePath = FlowtyUtils.getCapabilityStoragePath(type: nftType, suffix: "CollectionProviderForFlowty")

    let copiedCap = seller.storage.copy<Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>>(from: storagePath)
    if copiedCap != nil && copiedCap!.check() {
      nftCache[key] = copiedCap!
      return copiedCap!
    } else {
      // clean this storage slot in case something is there already
      seller.storage.load<AnyStruct>(from: storagePath)
      let cap = seller.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftStoragePath)
      assert(cap.check(), message: "invalid issued provider capability")
      seller.storage.save(cap, to: storagePath)
      nftCache[key] = cap

      return cap
    }
  }

  let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("Missing or mis-typed HybridCustody Manager")

  let child = manager.borrowAccount(addr: r.nftProviderAddress) ?? panic("no child account with that address")
  let providerCap = child.getCapability(controllerID: r.nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>())
    ?? panic("no nft provider found")
  let cap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
  nftCache[key] = cap

  return cap
}`

const bulkListingTxnNonCustodialCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import TransactionTypes from ${config.contractAddresses.TransactionTypes}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}

transaction(
  saleRequests: [TransactionTypes.StorefrontListingRequest],
  paymentReceiverAddress: Address,
  paymentReceiverPath: PublicPath,
  salePaymentVaultTypeIdentifier: String
) {
    prepare(seller: auth(Capabilities, Storage) &Account) {
        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
                seller.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if seller.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            seller.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = seller.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        let paymentType = CompositeType(salePaymentVaultTypeIdentifier) ?? panic("invalid payment type identifier")
        let paymentReceiver = getPaymentReceiver(seller, paymentReceiverAddress, paymentReceiverPath, paymentType)

        let nftCache: {String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = {}
        let nftRef: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = &nftCache

        let typeCache: {String: Type} = {}
        let typeRef: auth(Mutate) &{String: Type} = &typeCache

        let storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)!
        for request in saleRequests {
          createListing(seller, storefront, request, paymentReceiver, paymentType, nftRef, typeRef, cache)
        }
    }
}

access(all) fun createListing(
  _ seller: auth(Capabilities, Storage) &Account,
  _ storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ paymentReceiver: Capability<&{FungibleToken.Receiver}>,
  _ paymentType: Type,
  _ nftCache: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ typeCache: auth(Mutate) &{String: Type},
  _ capabilityCache: auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache
) {
  if typeCache[r.nftTypeIdentifier] == nil {
    typeCache[r.nftTypeIdentifier] = CompositeType(r.nftTypeIdentifier) ?? panic("invalid nft type identifier")
  }

  let collectionCap = getCollectionCap(seller, r, nftCache, typeCache[r.nftTypeIdentifier]!, capabilityCache)

  // check for existing listings of the NFT
  var existingListingIDs = storefront.getExistingListingIDs(
      nftType: typeCache[r.nftTypeIdentifier]!,
      nftID: r.nftID
  )
  // remove existing listings
  for listingID in existingListingIDs {
      storefront.removeListing(listingResourceID: listingID)
  }

  // Create listing
  storefront.createListing(
    nftProviderCapability: collectionCap,
    paymentReceiver: paymentReceiver,
    nftType: typeCache[r.nftTypeIdentifier]!,
    nftID: r.nftID,
    salePaymentVaultType: paymentType,
    price: r.price,
    customID: r.customID,
    expiry: UInt64(getCurrentBlock().timestamp) + r.expiry,
    buyer: r.buyerAddress
  )
}

access(all) fun getPaymentReceiver(
  _ seller: auth(Capabilities, Storage) &Account,
  _ paymentReceiverAddress: Address,
  _ paymentPath: PublicPath,
  _ paymentType: Type
): Capability<&{FungibleToken.Receiver}> {
  let tokenInfo = FlowtyUtils.getTokenInfo(paymentType) ?? panic("token info not found")
  let ftContractAddress = AddressUtils.parseAddress(paymentType)!
  let ftContractName = paymentType.identifier.split(separator: ".")[2]
  let ftContract = getAccount(ftContractAddress).contracts.borrow<&{FungibleToken}>(name: ftContractName)
    ?? panic("payment type does not implement FungibleToken")
  let ftVaultData = ftContract.resolveContractView(resourceType: paymentType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as!  FungibleTokenMetadataViews.FTVaultData

  if paymentReceiverAddress == seller.address {
    // Do we need to setup this vault for the signing account?
    if seller.storage.type(at: ftVaultData.storagePath) == nil {
      seller.storage.save(<- ftVaultData.createEmptyVault(), to: ftVaultData.storagePath)
      seller.capabilities.unpublish(ftVaultData.receiverPath)
      seller.capabilities.unpublish(ftVaultData.metadataPath)

      seller.capabilities.publish(seller.capabilities.storage.issue<&{FungibleToken.Receiver}>(ftVaultData.storagePath), at: ftVaultData.receiverPath)
      seller.capabilities.publish(seller.capabilities.storage.issue<&{FungibleToken.Vault}>(ftVaultData.storagePath), at: ftVaultData.metadataPath)
    }

    var cap = seller.capabilities.get<&{FungibleToken.Receiver}>(paymentPath)
    if !cap.check() {
      cap = seller.capabilities.get<&{FungibleToken.Receiver}>(paymentPath)
    }

    return cap
  }

  let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("Missing or mis-typed HybridCustody Manager")

  let child = manager.borrowAccount(addr: paymentReceiverAddress) ?? panic("no child account with that address")
  let cap = getAccount(paymentReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(paymentPath)

  return cap
}

access(all) fun getCollectionCap(
  _ seller: auth(Capabilities, Storage) &Account,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ nftCache: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ nftType: Type,
  _ capabilityCache: auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache
): Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}> {
  let key = r.nftProviderAddress.toString().concat(r.nftTypeIdentifier)
  if nftCache[key] != nil {
    return nftCache[key]!
  }


  if r.nftProviderAddress == seller.address {
    let borrowType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
    if let provider = capabilityCache.getCapabilityByType(resourceType: nftType, capabilityType: CapabilityType(borrowType)!) {
        nftCache[key] = provider as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    } else {
        let nftProvider = seller.capabilities.storage.issueWithType(r.nftStoragePath, type: borrowType) as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
        capabilityCache.addCapability(resourceType: nftType, cap: nftProvider!)
        nftCache[key] = nftProvider
    }

    return nftCache[key]!
  }

  let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("Missing or mis-typed HybridCustody Manager")

  let child = manager.borrowAccount(addr: r.nftProviderAddress) ?? panic("no child account with that address")
  let providerCap = child.getCapability(controllerID: r.nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>())
    ?? panic("no nft provider found")
  let cap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
  nftCache[key] = cap

  return cap
}`

const storefrontListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean
): string => {
	const isDapper = ["DUC", "FUT"].includes(token.symbol)
	return sellItem(config, token, nftData, isNftCatalog, isDapper)
}

const sellItem = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean,
	isDapper: boolean
): string => {
	if (isDapper) {
		return token.symbol === "DUC"
			? sellItemDapperBalance(config)
			: sellItemDapperFlow(config)
	}

	return isNftCatalog
		? sellItemHybridCustodyCatalog(config, token, nftData)
		: sellItemHybridCustodyNoCatalog(config, token, nftData)
}

const storefrontListingTxnCrescendo = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean
): string => {
	const isDapper = ["DUC", "FUT"].includes(token.symbol)

	if (isDapper) {
		return dapperCreateListing(config)
	}

	return isNftCatalog
		? sellItemHybridCustodyCatalogCrescendo(config)
		: sellItemHybridCustodyNoCatalogCrescendo(config)
}

const dapperCreateListing = (
	config: Config
): string => `import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}

/// If the given nft has a support of the RoyaltyView then royalties will added as the sale cut.
transaction(collectionIdentifier: String, saleItemID: UInt64, saleItemPrice: UFix64, customID: String?, buyer: Address?, expiry: UInt64) {
    let paymentReceiver: Capability<&{FungibleToken.Receiver}>
    let collectionCap: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront
    let nftType: Type

    prepare(seller: auth(Storage, Capabilities) &Account) {
        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            let storefront <- NFTStorefrontV2.createStorefront()
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)

            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
                seller.capabilities.storage.issue<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        // Receiver for the sale cut.
        self.paymentReceiver = seller.capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
        assert(self.paymentReceiver.borrow() != nil, message: "Missing or mis-typed DapperUtilityCoin receiver")

        let capStoragePath = FlowtyUtils.getCapabilityStoragePath(type: catalogEntry.nftType, suffix: "CollectionProviderForFlowtyNFTStorefront")
        let cap = seller.storage.copy<Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>>(from: capStoragePath)
        if cap != nil && cap!.check() {
            self.collectionCap = cap!
        } else {
            // clean this storage slot in case something is there already
            seller.storage.load<AnyStruct>(from: capStoragePath)
            self.collectionCap = seller.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.storagePath)
            seller.storage.save(self.collectionCap, to: capStoragePath)
        }

        assert(self.collectionCap.check(), message: "collection provider is invalid")

        let collection = self.collectionCap.borrow()
            ?? panic("Could not borrow a reference to the collection")
        let nft = collection.borrowNFT(saleItemID)
            ?? panic("nft could not be borrowed from provider collection")
        assert(nft.getType() == catalogEntry.nftType, message: "nftType must match type for catalog entry")
        self.nftType = catalogEntry.nftType

        self.storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.collectionCap,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: Type<@DapperUtilityCoin.Vault>(),
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemHybridCustodyCatalogCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}

/// Transaction used to facilitate the creation of the listing under the signer's owned storefront resource.
transaction(
  collectionIdentifier: String,
  saleItemID: UInt64,
  saleItemPrice: UFix64,
  customID: String?,
  buyer: Address?,
  expiry: UInt64,
  nftProviderControllerID: UInt64,
  nftProviderAddress: Address,
  ftReceiverAddress: Address,
  paymentTokenIdentifier: String
) {
    let paymentReceiver: Capability<&{FungibleToken.Receiver}>
    let nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront
    let nftType: Type
    let salePaymentVaultType: Type

    prepare(seller: auth(Capabilities, Storage) &Account) {
        self.salePaymentVaultType = CompositeType(paymentTokenIdentifier) ?? panic("invalid sale vault type")

        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
              seller.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
              at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if seller.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            seller.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = seller.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")        

        let ftAddress = AddressUtils.parseAddress(self.salePaymentVaultType)!
        let contractName = self.salePaymentVaultType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
            ?? panic("could not borrow fungible token contract")

        let ftVaultData = ftContract.resolveContractView(resourceType: self.salePaymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        if ftReceiverAddress == seller.address {
        		if seller.storage.type(at: ftVaultData.storagePath) == nil {
								seller.storage.save(<- ftVaultData.createEmptyVault(), to: ftVaultData.storagePath)
								seller.capabilities.unpublish(ftVaultData.receiverPath)
								seller.capabilities.unpublish(ftVaultData.metadataPath)
	
								seller.capabilities.publish(seller.capabilities.storage.issue<&{FungibleToken.Receiver}>(ftVaultData.storagePath), at: ftVaultData.receiverPath)
								seller.capabilities.publish(seller.capabilities.storage.issue<&{FungibleToken.Vault}>(ftVaultData.storagePath), at: ftVaultData.metadataPath)
            }

            self.paymentReceiver = seller.capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.paymentReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)!
        }

        assert(self.paymentReceiver.check(), message: "Missing or mis-typed token receiver")

        if nftProviderAddress == seller.address {
            let borrowType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
            if let provider = cache.getCapabilityByType(resourceType: catalogEntry.nftType, capabilityType: CapabilityType(borrowType)!) {
                self.nftProvider = provider as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            } else {
                self.nftProvider = seller.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.storagePath)
                cache.addCapability(resourceType: catalogEntry.nftType, cap: self.nftProvider)
            }
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.nftProvider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.borrow() != nil, message: "Missing or mis-typed NFT provider")

        let collection = self.nftProvider.borrow()
            ?? panic("Could not borrow a reference to the collection")
        let nft = collection.borrowNFT(saleItemID) ?? panic("nft could not be borrowed")
        self.nftType = nft.getType()

        self.storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.nftProvider,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: self.salePaymentVaultType,
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemHybridCustodyCatalog = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => ``

const sellItemHybridCustodyNoCatalog = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => ``

const sellItemHybridCustodyNoCatalogCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}

// Flowty - Sell item without the use of the NFT Catalog
// This transaction will list an item for sale on Flowty's Marketplace
// using the contract's NFTCollectionData metdata view.
//
// Importantly, if there is a conflicting link present on an account,
// this transaction will NOT override anything already present.
transaction(
    nftTypeIdentifier: String,
    saleItemID: UInt64,
    saleItemPrice: UFix64,
    customID: String?,
    buyer: Address?,
    expiry: UInt64,
    nftProviderControllerID: UInt64,
    nftProviderAddress: Address,
    ftReceiverAddress: Address,
    paymentTokenTypeIdentifier: String,
) {
    let paymentReceiver: Capability<&{FungibleToken.Receiver}>
    let collectionCap: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront
    let nftType: Type
    let paymentTokenType: Type

    prepare(seller: auth(Capabilities, Storage) &Account) {
        self.nftType = CompositeType(nftTypeIdentifier) ?? panic("invalid nft type identifier")
        self.paymentTokenType = CompositeType(paymentTokenTypeIdentifier) ?? panic("invalid payment token type identifier")

        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            let storefront <- NFTStorefrontV2.createStorefront()
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)

            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
                seller.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if seller.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            seller.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = seller.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        let ftAddress = AddressUtils.parseAddress(self.paymentTokenType)!
        let ftContractName = self.paymentTokenType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: ftContractName)
            ?? panic("could not borrow fungible token contract")

        let ftVaultData = ftContract.resolveContractView(resourceType: self.paymentTokenType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        let nftContractAddress = AddressUtils.parseAddress(self.nftType)!
        let nftContractName = self.nftType.identifier.split(separator: ".")[2]
        let c = getAccount(nftContractAddress).contracts.borrow<&{ViewResolver}>(name: nftContractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
        let md = c.resolveContractView(resourceType: self.nftType, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if ftReceiverAddress == seller.address {
            if seller.storage.type(at: ftVaultData.storagePath) == nil {
                seller.storage.save(<- ftVaultData.createEmptyVault(), to: ftVaultData.storagePath)
                seller.capabilities.unpublish(ftVaultData.receiverPath)
                seller.capabilities.unpublish(ftVaultData.metadataPath)

                seller.capabilities.publish(seller.capabilities.storage.issue<&{FungibleToken.Receiver}>(ftVaultData.storagePath), at: ftVaultData.receiverPath)
                seller.capabilities.publish(seller.capabilities.storage.issue<&{FungibleToken.Vault}>(ftVaultData.storagePath), at: ftVaultData.metadataPath)
            }

            self.paymentReceiver = seller.capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.paymentReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        }

        assert(self.paymentReceiver.check(), message: "Missing or mis-typed token receiver")

        if nftProviderAddress == seller.address {
            self.collectionCap = seller.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(collectionData.storagePath)
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.collectionCap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
        }

        let collection = self.collectionCap.borrow()
            ?? panic("Could not borrow a reference to the collection")
        let nft = collection.borrowNFT(saleItemID) ?? panic("nft not found")
        assert(self.nftType == nft.getType(), message: "borrowed nft type does not match supplied nft type")

        self.storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        let salePaymentVaultType = CompositeType(paymentTokenTypeIdentifier) ?? panic("invalid payment token type identifier")

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.collectionCap,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: salePaymentVaultType,
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemDapperBalance = (config: Config): string => ``

const sellItemDapperFlow = (config: Config): string => ``

const delistItem = (config: Config): string => ``

const delistItemCrescendo = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

// Remove a listing from the Flowty marketplace
transaction(listingResourceID: UInt64) {
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}

    prepare(acct: auth(Storage) &Account) {
        self.storefront = acct.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront")
    }

    execute {
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}`

const delistSharedStorefrontItem = (config: Config): string => ``

// there isn't a fully migrated shared storefront yet.
const delistItemSharedStorefrontItemCrescendo = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}

transaction(listingResourceID: UInt64) {
    prepare(acct: auth(BorrowValue) &Account) {
        let storefront = acct.storage.borrow<auth(NFTStorefrontV2.RemoveListing) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("storefront not found")
        
        storefront.removeListing(listingResourceID: listingResourceID)
    }
}`

const bulkDelistTxn = (config: Config): string => ``

const bulkDelistTxnCrescendo = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

// Remove multiple listings from the Flowty marketplace
transaction(existingListingIDs: [UInt64]) {
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}
    prepare(acct: auth(Storage) &Account) {
        self.storefront = acct.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront")
    }
    execute {
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }
    }
}`
