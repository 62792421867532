/* eslint-disable @typescript-eslint/no-explicit-any */
import * as fcl from "@onflow/fcl"
import axios from "axios"
import { User as FbUser, onAuthStateChanged } from "firebase/auth"
import { AccountData, getMessageFromError, PublicAccount } from "flowty-common"
import Swal from "sweetalert2"
import { flowty, resetConfig } from "../config/config"
import { auth } from "../firebase"
import { User } from "../models/user"
import { Err, Log } from "../util/Log"
import { actions as Mixpanel } from "../util/Mixpanel"
import { apiURL } from "../util/settings"

import {
	getAccountData,
	getAccountToken,
	isAccountExists,
	setAccountData,
} from "./firestore/AccountService"
import { verifyAddress } from "./flow/AccountService"

export const logOut = (): void => {
	localStorage.removeItem("FCL_CURRENT_USER")

	fcl.unauthenticate()
	auth.signOut().then(() => {
		Log("firebase sign out successful")
	})

	fcl.config.put({
		"discovery.authn.endpoint":
			process.env.REACT_APP_WALLET_DISCOVERY_ENDPOINT_V2,
		"discovery.wallet": process.env.REACT_APP_WALLET_DISCOVERY_V2,
	})
	resetConfig()

	sessionStorage.clear()
}

export const subscribeForAuthChange = (
	setUserCallback: (user: User | null) => void,
	onSubscribeError: (error: string) => void,
	loggedInUser: FbUser | null,
	setLoadingUserCallback: (val: boolean) => void
): void => {
	// Listen to FCL for our Auth Status
	// if we hae a user, we will first grab some data frm flow (child accounts, hybrid custody providers, etc)
	// then we'll try to get our wallet balance.
	// finally we will take care of our Firebase user status.
	// both of these need to be logged in for us to be logged in.
	const localStorageUser = localStorage.getItem("FCL_CURRENT_USER")

	let localStorageUserObj
	if (localStorageUser && localStorageUser !== "") {
		try {
			localStorageUserObj = JSON.parse(localStorageUser)
		} catch (error) {
			console.error("Error parsing localStorageUser:", error)
		}
	}

	if (
		Boolean(localStorageUserObj?.expiresAt) &&
		localStorageUserObj?.expiresAt < Date.now()
	) {
		logOut()
		return
	}

	if (localStorageUser) {
		try {
			if (!sessionStorage.getItem("CURRENT_USER")) {
				sessionStorage.setItem("CURRENT_USER", localStorageUser) // so that current user flow works
			}
		} catch (error) {}

		localStorage.removeItem("FCL_CURRENT_USER")
	}

	fcl.currentUser().subscribe(async (currentUser: User) => {
		// if there is a user, preserve it to local storage
		if (currentUser) {
			localStorage.setItem("FCL_CURRENT_USER", JSON.stringify(currentUser))
		}

		Log("fcl subscribe currentUser", { currentUser })

		// get firebase auth state
		const fbUser = loggedInUser
		Log("currentUser is", currentUser?.addr, currentUser?.token, fbUser)

		const isDapper =
			(currentUser?.services?.length > 0 &&
				currentUser.services[0].endpoint &&
				currentUser.services[0].endpoint.includes("meetdapper.com")) ||
			false

		const user = currentUser
		if (!user) {
			setUserCallback(null)
			return
		}

		// TODO: can be make most of this all async so it is done in parallel?
		setLoadingUserCallback(true)
		const accountAddress = user.addr
		if (accountAddress) {
			const balancePromise =
				flowty.scripts.getAccountWalletBalance(accountAddress)
			Log("accountAddress", accountAddress)

			const blockToEmail = user.services[0]
				? { email: user.services[0].scoped?.email } || {}
				: {}

			let customToken = user?.token || ""

			let firebaseSignedUser: any
			try {
				Log("trying to login with firebase")
				if (!fbUser) {
					Mixpanel.track("[PREVIEW 2-4233]NoFbUser", {})
					// probably at this point we don't want to try the custom token
					if (!customToken) {
						throw new Error("Not authenticated")
					}

					// eslint-disable-next-line @typescript-eslint/no-use-before-define
					firebaseSignedUser = await signInWithCustomToken(customToken)
				} else {
					Mixpanel.track("[PREVIEW 2-4233]YesFbUser", {})

					firebaseSignedUser = {
						user: fbUser,
					}
				}
				Log("obtained firebaseSignedUser...")
			} catch (e) {
				Log("something went wrong logging in: ", e)
				Log("getting user signed message...")

				Mixpanel.track("[PREVIEW 2-4233]CatchBlock", {
					message: (e as Error)?.message,
				})

				customToken = await getAccountToken(currentUser)
				if (!customToken) {
					logOut()
				}

				Mixpanel.track("[PREVIEW 2-4233]createAccountToken", {
					customToken,
				})
				Log("obtained custom token, logging in...")
				try {
					// eslint-disable-next-line @typescript-eslint/no-use-before-define
					firebaseSignedUser = await signInWithCustomToken(customToken)
					await new Promise((res, rej) => {
						const stateChanged = onAuthStateChanged(
							auth,
							authUser => {
								if (authUser) {
									stateChanged()
									res(authUser)
								}
								// this should never happen. When the user signs in with a custom token
								// we should be able to resovle that user
								// however, in practice, if there's a crazy long wait, better
								// to refresh. Then they're logged in.
								const timeout = setTimeout(() => {
									Mixpanel.track("[PREVIEW 2-4233]ForcedRefresh", {})
									window.location.reload()
								}, 1500)

								clearTimeout(timeout)
							},
							err => {
								stateChanged()
								rej(err)
							}
						)
					})
				} catch (e2) {
					const errMessage = getMessageFromError(e2)
					if (errMessage.includes("Popup failed to open")) {
						await Swal.fire({
							icon: "error",
							showConfirmButton: true,
							timer: 2500,
							title:
								"Pop-up blocker is enabled. Please allow pop-ups and try again.",
						})
					}

					Err(
						"something went wrong logging in with new signature: ",
						getMessageFromError(e2)
					)
					// eslint-disable-next-line @typescript-eslint/no-use-before-define
					logOut()
					return
				}
			}

			// Continue with logging into the app only if firebase user was successfully authenticated
			if (firebaseSignedUser?.user?.uid === accountAddress) {
				Log("checking if account exists...", accountAddress)
				Mixpanel.track("[PREVIEW 2-4233]AccountExists", {})

				let accountExists: boolean = false
				try {
					accountExists = await isAccountExists(accountAddress)
				} catch (e) {
					Mixpanel.track("ERROR_FIREBASE_CHECK_ACCOUNT_EXISTS", { e })
				}

				Mixpanel.track("[PREVIEW 2-4233]AccountExists?", {
					accountExists,
				})

				if (!accountExists && accountAddress) {
					Log("account does not exist yet", accountAddress)
					Mixpanel.track("[PREVIEW 2-4233]No Account", { accountAddress })

					try {
						await setAccountData(accountAddress, { email: "" })
					} catch (e) {
						Mixpanel.track("ERROR_FIREBASE_SET_ACCOUNT_DATA", { e })
					}
				}

				Log("signed in user matches account address")
				Mixpanel.track("[PREVIEW 2-4233]getAccountData", { accountAddress })

				const childAccountSummaryPromise =
					flowty.scripts.getHybridCustodyAccountSummaries(user?.addr || "")

				let childAccountsPromise: any = null
				let childAccountBalancesPromise: any = null
				try {
					childAccountsPromise = flowty.scripts.getChildAccounts(
						user?.addr ?? ""
					)
					childAccountBalancesPromise = flowty.scripts
						.getHybridCustodyFTProvidersScript(user?.addr || "")
						.then(async res => {
							if (!res) {
								return
							}

							const accounts = Object.entries(res)
							const result: { [key: string]: any } = {}
							for (let i = 0; i < accounts.length; i++) {
								const [address, providers] = accounts[i]
								const keys = Object.keys(providers)
								const balance =
									await flowty.scripts.getBalanceForAvailableProvidersScript(
										address,
										keys
									)
								result[address] = keys.reduce((acc, curr) => {
									return {
										...acc,
										[curr]: {
											balance: balance[curr],
											domain: providers[curr]?.domain,
											identifier: providers[curr]?.identifier,
										},
									}
								}, {})
							}

							return result
						})
				} catch (e) {
					Log("error on script child account execution", e)
				}

				getAccountData(accountAddress)
					.then(async (accountData: AccountData) => {
						Mixpanel.track("[PREVIEW 2-4233]getAccountDataResult", {
							accountData,
						})

						const [
							balance,
							childAccounts,
							childAccountBalances,
							childSummaries,
						] = await Promise.all([
							balancePromise,
							childAccountsPromise,
							childAccountBalancesPromise,
							childAccountSummaryPromise,
						])

						const childAccountInfo = Object.keys(childAccounts || {}).reduce(
							(accounts, address) => {
								if (childAccounts === null) {
									return accounts
								}
								return {
									...accounts,
									[address]: {
										...childAccounts[address],
										tokens: childAccountBalances
											? childAccountBalances[address]
											: {},
									},
								}
							},
							{}
						)

						const accountSummaries = {
							...childSummaries,
						}
						accountSummaries[accountAddress] = {
							...(childSummaries?.[accountAddress] || {}),
							display: {
								description: "Main Account",
								name: accountData.userName || accountAddress,
								thumbnail: accountData.avatar || "", // TODO: default image?
							},
							isDapper,
							isMain: true,
						}

						const userObj: User = {
							...user,
							...accountData,
							...{ blockToEmail: blockToEmail.email },
							...{ isFirstLogin: !accountExists },
							...{ token: customToken },
							accountSummaries,
							balance,
							childAccounts: childAccountInfo,
							isDapper,
						}

						setUserCallback(userObj)
					})
					.catch((err: any) => {
						Mixpanel.track("ERROR_FIREBASE_GET_ACCOUNT_DATA", {
							err,
							message: err?.message,
						})
						Mixpanel.track("[PREVIEW 2-4233]onSubscribeError", {
							message: err?.message,
						})
						onSubscribeError(err.toString())
					})
				Mixpanel.track("Successful Login")
			} else {
				Err("User was not authenticated successfully!", firebaseSignedUser)
				Mixpanel.track("Failed Login")
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				logOut()
			}
		} else {
			setUserCallback(null)
		}
	})
}

const signInWithCustomToken = (token: string): Promise<any> => {
	return auth.signInWithCustomToken(token)
}

export const getPublicAccount = async (
	address: string
): Promise<PublicAccount> => {
	Log("getPublicAccount", address)

	const defaultAccount: PublicAccount = {
		addr: address,
		avatar: "",
		childAccounts: null,
		lastProcessed: Date.now(),
		processed: false,
		userName: address,
		walletAddress: address,
	}

	if (!address || address === "") {
		return defaultAccount
	}

	try {
		const valid = await verifyAddress(address)
		if (!valid) {
			return defaultAccount
		}

		const childAccountsPromise = flowty.scripts.getChildAccounts(address)

		const res = await axios.get<PublicAccount>(
			`${apiURL}/user/${address}/details`
		)
		if (res.status !== 200) {
			return defaultAccount
		}

		const childAccounts = await childAccountsPromise
		return { ...res.data, childAccounts, walletAddress: address }
	} catch (e) {
		Err("failed to get account due to ", e)
		return defaultAccount
	}
}
