import { formatInTimeZone } from "date-fns-tz"
import { FlowtyCreatorHubFormValues } from "../../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"
import { TwitterShareButton } from "../TwitterShareButton"

function createNewDropTwitterHref({
	values,
	collectionPageTwitterURL,
}: {
	values?: FlowtyCreatorHubFormValues
	collectionPageTwitterURL?: string
}) {
	let formattedDate = ""

	if (values?.dropStartDateTime) {
		formattedDate = formatInTimeZone(
			new Date(values?.dropStartDateTime),
			"UTC",
			"MMMM do, yyyy 'at' h:mm a 'UTC'"
		)
	}

	const encodedCollectionName = encodeURIComponent(values?.collectionName ?? "")
	const encodedCollectionPageTwitterURL = encodeURIComponent(
		collectionPageTwitterURL ?? ""
	)

	return `https://twitter.com/intent/tweet?text=I%20just%20created%20a%20new%20collection%20called%20%22${encodedCollectionName}%22%20using%20the%20NFT%20Launchpad%20on%20%40flowty_io%21%20🎨%0A%0AMinting%20goes%20live%20on%20${formattedDate}%20here%3A%20${encodedCollectionPageTwitterURL?.trim()} `
}

type ShareNewDropProps = {
	values?: FlowtyCreatorHubFormValues
	collectionPageTwitterURL?: string
}
export const ShareNewDrop: React.FC<ShareNewDropProps> = ({
	values,
	collectionPageTwitterURL,
}) => {
	const href = createNewDropTwitterHref({
		collectionPageTwitterURL,
		values,
	})

	return <TwitterShareButton href={href} />
}
