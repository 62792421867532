import { ReactComponent as TwitterShareIcon } from "../../assets/twitter_icon.svg"
import { Tooltip } from "react-tooltip"
import { formatInTimeZone } from "date-fns-tz"
import { DROPSTATUS } from "../../FlowtyDropPage/types/DropType"

function createNewPreMintTwitterHref({
	collectionDisplayName,
	dropPageLink,
	dropStartDate,
	dropStatus,
	isDropOwner,
}: {
	collectionDisplayName?: string
	dropPageLink?: string
	dropStartDate?: string
	dropStatus?: DROPSTATUS
	isDropOwner?: boolean
}): string {
	let formattedDate = ""
	if (dropStartDate) {
		formattedDate = formatInTimeZone(
			new Date(parseInt(dropStartDate, 10) * 1000),
			"UTC",
			"MMMM do, yyyy 'at' h:mm a 'UTC'"
		)
	}
	const encodedCollectionName = encodeURIComponent(collectionDisplayName ?? "")

	const baseTweetUrl = "https://twitter.com/intent/tweet?text="
	const tweetMessages: { [key: string]: string } = {
		active: `Minting%20for%20%22${encodedCollectionName}%22%20is%20LIVE%20on%20%40flowty_io%0A%0ACheck%20out%20the%20drop%20here%3A%20${dropPageLink}`,
		owner: `I%20just%20created%20a%20new%20collection%20called%20%22${encodedCollectionName}%22%20using%20the%20NFT%20Launchpad%20on%20%40flowty_io!%20%F0%9F%8E%A8%0A%0AMinting%20goes%20live%20on%20${formattedDate}%20here%3A%20${dropPageLink}`,
		pending: `Excited%20to%20mint%20a%20new%20NFT%20from%20%22${encodedCollectionName}%22%20on%20%40flowty_io!!%0A%0AJoin%20me%20for%20the%20drop%20on%20${formattedDate}%20here%3A%20${dropPageLink}`,
	}

	if (isDropOwner) {
		return `${baseTweetUrl}${tweetMessages.owner}`
	}

	const statusMapping: { [key: string]: string } = {
		ACTIVE: tweetMessages.active,
		PENDING: tweetMessages.pending,
	}

	if (!dropStatus) {
		return ""
	}

	return statusMapping[dropStatus]
		? `${baseTweetUrl}${statusMapping[dropStatus]}`
		: ""
}

type ShareNewPreMintProps = {
	collectionDisplayName?: string
	dropPageLink?: string
	dropStartDate?: string
	dropStatus?: DROPSTATUS
	isDropOwner?: boolean
}
export const ShareNewPreMint: React.FC<ShareNewPreMintProps> = ({
	collectionDisplayName,
	dropPageLink,
	dropStartDate,
	dropStatus,
	isDropOwner,
}) => {
	const href = createNewPreMintTwitterHref({
		collectionDisplayName,
		dropPageLink,
		dropStartDate,
		dropStatus,
		isDropOwner,
	})

	const tooltipCustomId = "tweet-drop-page"
	const renderContent = "Share on Twitter"

	return (
		<>
			<Tooltip
				id={`${tooltipCustomId}-tooltip`}
				className='flex items-center'
				style={{
					backgroundColor: "#F8F9FA",
					color: "#031021",
					fontSize: "14px",
					fontWeight: 500,
					height: "33.999px",
					lineHeight: "normal",
					padding: "12px",
					pointerEvents: "auto",
					textAlign: "center",
					zIndex: 40,
				}}
				opacity={100}
				clickable={false}
				render={() => <>{renderContent}</>}
			/>
			<div
				data-tooltip-id={`${tooltipCustomId}-tooltip`}
				data-tooltip-place='top'
			>
				<button
					className='border rounded p-[8px]'
					style={{ border: "1px solid var(--Neutral-300, #495057)" }}
					onClick={() => {
						window.open(href, "_blank")
					}}
					rel='noreferrer'
				>
					<TwitterShareIcon className='h-5 w-5' />
				</button>
			</div>
		</>
	)
}
