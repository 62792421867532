import { DROPSTATUS, DropType } from "ds-flowty"
import { FlowNFTContract } from "flowty-common"
import { useEffect, useMemo, useState } from "react"
import { flowty } from "../../../config/config"
import AuthStore from "../../../stores/AuthStore"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import { removeSpecialChars } from "ds-flowty/src/FlowtyCreatorHub/components/FormView/components/ConfirmationFormStep/components/CollectionConfirmationFormStep"

export type AccountContracts = {
	address: string
	contracts: FlowNFTContract[]
}

export interface MergedCollections extends FlowNFTContract {
	drops: DropType[]
	status: DROPSTATUS
}

interface ProfileCreatorHubValues {
	isLoading: boolean
	sortedCollections: MergedCollections[]
	getDropStatus: (contract: DropType) => DROPSTATUS
}

interface Props {
	authStore: AuthStore | undefined
}

export const useProfileCreatorHub = ({
	authStore,
}: Props): ProfileCreatorHubValues => {
	const [isLoading, setIsLoading] = useState(true)
	const [mergedCollectionData, setMergedCollectionData] = useState<
		MergedCollections[]
	>([])

	if (!authStore?.loggedUser?.loggedIn) {
		window.location.replace("/")
	}

	const getUserOwnedCollections = async (): Promise<AccountContracts> => {
		try {
			const response = await flowty.scripts.getAccountCollections(
				authStore?.loggedUser?.addr ?? ""
			)
			return response as AccountContracts
		} catch (error) {
			Mixpanel.track("Error getting logged user account collections", error)
		}
		return { address: "", contracts: [] }
	}

	const getDropStatus = (contract: DropType): DROPSTATUS => {
		const firstPhase = contract?.phases[0]
		if (!firstPhase) {
			return "NO_PHASES"
		}

		if (!firstPhase.hasStarted && !firstPhase.hasEnded) {
			return "PENDING"
		}

		if (firstPhase.hasStarted && !firstPhase.hasEnded) {
			if (!firstPhase.start && !firstPhase?.end) {
				return "NEVER_ENDING"
			} else {
				return "ACTIVE"
			}
		}

		return "ENDED"
	}

	const mergeDropDataWithCollections = (
		dropData: DropType[],
		ownedCollections: AccountContracts
	): MergedCollections[] => {
		const mergedData: MergedCollections[] = []
		ownedCollections?.contracts.forEach(contract => {
			const relevantCollections = dropData.filter(drop => {
				const [_, address, name] = drop.nftType.split(".")
				return (
					`0x${address}` === ownedCollections.address &&
					name === removeSpecialChars(contract.collectionDisplay?.name ?? "")
				)
			})
			mergedData.push({
				...contract,
				drops: relevantCollections,
				status: getDropStatus(relevantCollections?.[0]),
			})
		})
		return mergedData
	}

	const getCollectionDropData = async (
		collection: FlowNFTContract,
		address: string
	): Promise<DropType[]> => {
		const nftResourceTypeIdentifier = `A.${address.substring(
			2
		)}.${collection?.collectionDisplay?.name.replace(/\s/g, "")}.${"NFT"}` // Do we need to pass in resource || "NFT"

		const dropData = await flowty.getAllDropDetails({
			minter: address || undefined,
			nftResourceTypeIdentifier,
			paymentIdentifier: "A.7e60df042a9c0868.FlowToken.Vault",
			quantity: 1,
		})
		return dropData
	}

	useEffect(() => {
		const fetchData = async (): Promise<void> => {
			const ownedCollections = await getUserOwnedCollections()

			if (!ownedCollections?.contracts.length || !ownedCollections?.address) {
				setIsLoading(false)
				return
			}
			const collectionDetails = await getCollectionDropData(
				ownedCollections?.contracts[0],
				ownedCollections?.address
			)

			const mergedData = mergeDropDataWithCollections(
				collectionDetails,
				ownedCollections
			)
			setMergedCollectionData(mergedData)
			setIsLoading(false)
		}
		fetchData()
	}, [])

	const sortedCollections: MergedCollections[] = useMemo(() => {
		return mergedCollectionData.sort((a, b) => {
			const order = {
				ACTIVE: 1,
				ENDED: 3,
				NEVER_ENDING: 1,
				NO_PHASES: 3,
				PENDING: 2,
				SOLD_OUT: 3,
			}

			const comparison = (order[a.status] || 3) - (order[b.status] || 3)

			if (comparison === 0) {
				return (
					parseInt(a.drops[0]?.phases[0]?.start) -
					parseInt(b.drops[0]?.phases[0]?.start)
				)
			}
			return comparison
		})
	}, [mergedCollectionData, isLoading])

	return {
		getDropStatus,
		isLoading,
		sortedCollections,
	}
}
