import { FlowtyButton } from "../../../../FlowtyButton"
import { CreatorHubNavProps } from "../../../../FlowtyCreatorHub/hooks/useCreatorHubModal"

interface CreatorHubModalNavViewProps {
	modalNavProps: CreatorHubNavProps
}

export const CreatorHubModalNavView: React.FunctionComponent<
	CreatorHubModalNavViewProps
> = ({ modalNavProps }) => {
	if (!modalNavProps) {
		return null
	}

	if (Array.isArray(modalNavProps)) {
		return (
			<div className='w-full flex items-center gap-[24px]'>
				{modalNavProps.map((btn, index) => (
					<FlowtyButton
						key={index}
						text={btn.text}
						onClick={btn.onClick}
						variant='secondary'
						btnSize='large'
						disabled={btn.disabled}
						bgColor={btn.bgColor}
					/>
				))}
			</div>
		)
	}

	return (
		<div className='w-full flex flex-col gap-1'>
			<FlowtyButton
				text={modalNavProps.text}
				onClick={modalNavProps.onClick}
				variant='secondary'
				btnSize='large'
				disabled={modalNavProps.disabled}
				className='h-[35px] flex items-center justify-center px-4'
			/>
		</div>
	)
}
