import { useField } from "formik"
import React from "react"
import { CreatorHubDisplayField } from "../../../../common/CreatorHubDisplayField/CreatorHubDisplayField"
import { CreatorHubSocialsField } from "../../../../common/CreatorHubSocialsField/CreatorHubSocialsField"
import { CollectionImagesConfirmation } from "./CollectionImagesConfirmation"

export const removeSpecialChars = (str: string) => {
	return str.replace(/[^a-zA-Z0-9_]/g, "")
}

export const CollectionConfirmationFormStep: React.FunctionComponent = () => {
	const [collectionName] = useField("collectionName")
	const [collectionDescription] = useField("collectionDescription")
	const [collectionWebsiteURL] = useField("collectionWebsiteURL")
	const [mintPrice] = useField("mintPrice")
	const [royalty] = useField("royalty")
	const [twitterHandle] = useField("twitterHandle")
	const [instagramHandle] = useField("instagramHandle")
	const [discordHandle] = useField("discordHandle")
	const [dropStartDateTime] = useField("dropStartDateTime")
	const [dropEndDateTime] = useField("dropEndDateTime")

	const dateFormatter = (date: number): string => {
		const dateObj = new Date(date)
		const options: Intl.DateTimeFormatOptions = {
			day: "numeric",
			hour: "numeric",
			hour12: true,
			minute: "numeric",
			month: "long",
			year: "numeric",
		}
		return dateObj.toLocaleString("en-US", options)
	}

	return (
		<div className='flex flex-col gap-[24px]'>
			<div className='flex flex-col gap-[12px]'>
				<div className='text-white text-[18px] font-black pb-2'>
					Collection Information
				</div>
				<CreatorHubDisplayField
					title={"Collection Name"}
					details={collectionName.value}
				/>
				<CreatorHubDisplayField
					title={"Description"}
					details={collectionDescription.value}
				/>
				<CreatorHubDisplayField
					title={"Contract Name"}
					details={removeSpecialChars(collectionName.value)}
				/>
			</div>

			<div className='flex flex-col gap-[12px]'>
				<div className='text-white text-[18px] font-black pb-2'>
					Mint + Royalty Information
				</div>
				<CreatorHubDisplayField
					title={"Mint Price"}
					details={Number(mintPrice.value) ? mintPrice.value : "FREE"}
					isPrice={Boolean(mintPrice.value)}
				/>
				<CreatorHubDisplayField
					title={"Start Date & Time (Local Time Zone)"}
					details={dateFormatter(dropStartDateTime.value)}
				/>
				<CreatorHubDisplayField
					title={"End Date & Time (Local Time Zone)"}
					details={dateFormatter(dropEndDateTime.value)}
				/>
				<CreatorHubDisplayField
					title={"Royalty"}
					details={`${Number(royalty.value)}%`}
				/>
			</div>

			<CreatorHubSocialsField
				discordAccount={discordHandle.value}
				instagramAccount={instagramHandle.value}
				twitterAccount={twitterHandle.value}
				websiteUrl={collectionWebsiteURL.value}
			/>
			<CollectionImagesConfirmation />
		</div>
	)
}
